import React from 'react'
import PageHeroSideElements from './PageHeroSideElements'
import PropTypes from 'prop-types'

import './PageHero.scss'

export default function PageHero({slug, title, subtitleSection, copyright}) {

  const {rightOrUnder, subtitle} = subtitleSection
  
  const subtitleClass = () => {
    switch(slug){
      case 'homepage':
        return '--home'
      case 'contact':
        return '--contact'
      case 'work':
        return '--work'
      case 'jobs':
        return '--jobs'
      default:
        return ''
    }
  }

  return (
    <div className="hero">
      <div className="content-wrapper">
        <div className="hero__content">
          <h1 className={`hero__content-title hero__content-title${subtitleClass()}`}>{title}</h1>
          <p className={`hero__content-subtitle${subtitleClass()} hero__content-subtitle--${rightOrUnder}`}>
            {subtitle}
          </p>
        </div>
      </div>
      <PageHeroSideElements copyright={copyright} />
    </div>
  )
}

PageHero.propTypes = {
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitleSection: PropTypes.object.isRequired,
  copyright: PropTypes.string.isRequired
}