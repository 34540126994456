import React, {useEffect, useRef} from 'react'
import PropTypes from 'prop-types'
// hooks
import { useScrollPosition } from '../hooks/useScrollPosition'
import { useCurrentWidth, useCurrentHeight } from '../hooks/useResize'
import useIntersect from '../hooks/useIntersect'
//images
import B from '../svg-images/b.svg'
// scss
import './ServicesComponent.scss'

import {getMousePosition} from '../utils/utils'

import ServicesComponentItems from './ServicesComponentItem';

/**
 * ServicesComponent component
 * 
 * @param {array} param.servicesRepeater 
 * 
 * @returns {JSX Element}
 */
export default function ServicesComponent({servicesRepeater}) {

  const offsetStartPoint = useRef(null);
  const transitionValue = useRef('');

  const container = useRef(null)
  const svg = useRef(null)
  const items = useRef([]);

  const windowWidth = useCurrentWidth();
  const windowHeight = useCurrentHeight();
  
  const [ref, entry] = useIntersect({
    root: null,
    rootMargin: '0px 0px 0px'
  })
  
  const isDesktopSize = () => windowWidth > 1023

  const containerOffsetTop = () => container.current.offsetTop - windowHeight
  const containerOffsetBottom = () => container.current.offsetTop + container.current.offsetHeight

  
  /**
   * distance
   * 
   * Calculates the distance between image and cursor
   * @param {Object} imagePos 
   * @param {Object} cursorPos 
   * @returns null
   */
  const distance = (imagePos, cursorPos) => Math.hypot(cursorPos.x - imagePos.x, cursorPos.y - imagePos.y);

  /**
   * elementDisplacement
   * 
   * Calculates position of where element should be(for tracking mouse with delay)
   * @param {Number} prevPos - last position of mouse
   * @param {Number} currPos - current mouse position
   * @param {Number} n - how "strong" is delay
   * @returns {Number} - position of element behind cursor
   */
  const elementDisplacement = (prevPos, currPos, n) => (1 - n) * prevPos + n * currPos

  /**
   * getHalfImageSize
   * 
   * calculates the image size for differet screen sizes
   * @param {Number} screenSize 
   * @returns {Object} half image width and height
   */
  const getHalfImageSize = (screenSize) => {
    if(screenSize < 1320) {
      return {width: 119, height: 178}
    }
    else if(screenSize > 1319 && screenSize < 1920) {
      return {width: 180, height: 210}
    }
    else {
      return {width: 190, height: 220}
    }
  }

  /**
   * setCursorStartingPoint
   * 
   * Sets starting point for image when cursor enters the container
   * @param {Object} e 
   * @param {Object} container 
   * @param {Function} setState 
   */
  const setCursorStartingPoint = (e, container, setState) => {
    const cursorPos = getMousePosition(e, container)
    const imageSize = getHalfImageSize(windowWidth)
    setState({x: cursorPos.x - imageSize.width, y: cursorPos.y - imageSize.height});
  }

  /**
   * getImagePosition
   * 
   * Gets image position inside a container
   * @param {Ref} image 
   * @param {Ref} container 
   * @returns {Object} image coordinates(x, y)
   */
  const getImagePosition = (image, container) => {
    const imagePos = image.current.getBoundingClientRect();
    const containerPosition = container.current.getBoundingClientRect();
    return {
      x: (imagePos.left + imagePos.width / 2) - containerPosition.left,
      y: (imagePos.top + imagePos.height / 2) - containerPosition.top
    }
  }

  /**
   * handleMouseIn
   * 
   * Sets state on mouse enter
   * @param {Boolean} state 
   * @param {Function} setState 
   * @returns null
   */
  const handleMouseIn = (state, setState) => !state && setState(true)

  /**
   * handleMouseOut
   * 
   * Sets state on mouse leave
   * @param {Boolean} state 
   * @param {Function} setState 
   * @returns null
   */
  const handleMouseOut = (state, setState) => state && setState(false)
  
  /**
   * updateImagePosition
   * 
   * Updates image position inside a container following cursor
   * @param {Event} e
   * @param {Ref} container
   * @param {Ref} image
   * @returns null
   */
  const updateImagePosition = (image, cursorPos) => {
    if(image.current) {
      image.current.style.transform = `translate3d(${
        cursorPos.x - image.current.offsetWidth / 2}px, ${
        cursorPos.y - image.current.offsetHeight / 2}px, 0px)`
    }
  }

  // setting startin point for parallax on load
  useEffect(() => {
    if(isDesktopSize()) offsetStartPoint.current = -Math.abs(containerOffsetTop())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  // handeling layout on window resize
  useEffect(() => {
    if(!transitionValue.current) transitionValue.current = items.current[0].style.transform

    if(!isDesktopSize()) {
      items.current.forEach(element => {
        element.style.transform = `translateY(0px)`
      })
    }
    else {
      items.current.forEach(element => {
        element.style.transform = `${transitionValue.current}`
      })
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowWidth])

  // handeling parallax effect on scroll
  useScrollPosition(({ currPos }) => {
    const currentScrollPosition = Math.abs(currPos.y)

    if(isDesktopSize() && currentScrollPosition > containerOffsetTop() && currentScrollPosition < containerOffsetBottom()) {
      items.current.forEach(element => {
        element.style.transform = `translateY(${(currPos.y - offsetStartPoint.current)/10}px)`
      })
      svg.current.style.transform = `translateY(${(currPos.y - offsetStartPoint.current)/15}px)`
    }
  }, entry.isIntersecting)

  return(
    <div ref={ref}>
      <div className="services" ref={container}>
        <div className="content-wrapper">
          <div className="services__b" ref={svg}>
            <B className="services__b-svg"/>
          </div>
          <span className="services__label">/SERVICES</span>
          <div className="services__repeater">
            {
              servicesRepeater.map((item, index) => {
                return (
                  <ServicesComponentItems 
                    item={item} 
                    index={index} 
                    handleMouseIn={handleMouseIn}
                    handleMouseOut={handleMouseOut}
                    windowWidth={windowWidth}
                    setCursorStartingPoint={setCursorStartingPoint}
                    updateImagePosition={updateImagePosition}
                    distance={distance}
                    getImagePosition={getImagePosition}
                    elementDisplacement={elementDisplacement}
                    key={index} 
                    ref={(element) => items.current[index] = element}
                  />
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
  )
}

ServicesComponent.propTypes = {
  servicesRepeater: PropTypes.array.isRequired
}