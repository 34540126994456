import React from 'react'
import PropTypes from 'prop-types'

import { getImage } from 'gatsby-plugin-image';
import BackgroundImage from 'gatsby-background-image'

import { convertToBgImage } from '../../utils/utils'


/**
 * ContactInfo component
 * 
 * @param {Array} infoRepeater
 * @param {Object} image 
 * 
 * @returns 
 */
export default function ContactInfo({ infoRepeater, image }) {

  const contactInfoImage = getImage(image.localFile.childImageSharp.gatsbyImageData)

  const bgImage = convertToBgImage(contactInfoImage)

  return (
    <div className="contact__infos">
      {
        image && 
        <BackgroundImage
          Tag="section"
          {...bgImage}
          preserveStackingContext
          className="contact__image"
          alt={image.altText ? image.altText : 'Contact Info'}
        />
      }
      <div className="contact__info-content"> 
        <div className="contact__info-title">/Contact info</div>
        <div className="contact__info-boxes">
          {
            infoRepeater.map((item, index) => {
            
              return (
                <div className="contact__info-box" key={index}>
                  <h2 className="contact__info-box-title">{item.infoTitle}</h2>
                  {
                    item.infos.map((info, index) => {
                      
                      return (
                        <div className="contact__info-box-field" key={index}>
                          {
                            (() => {
                              switch(info.singleInfoType) {
                                case 'text':
                                  return (
                                    <p>{info.singleInfo}</p>
                                  )
                                case 'email':
                                  return (
                                    <a href={`mailto:${info.singleInfo}`}>{info.singleInfo}</a>
                                  )
                                case 'phone':
                                  return (
                                    <a href={`tel:${info.singleInfo}`}>{info.singleInfo}</a>
                                  )
                                default:
                                  return null
                              }
                            })()
                          }
                        </div>
                      )
                    })
                  }
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

ContactInfo.propTypes = {
  infoRepeater: PropTypes.array,
  image: PropTypes.object
}