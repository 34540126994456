import React from 'react'
import PropTypes from 'prop-types'

import Arrow from '../images/arrow.svg'
import './CtaButton.scss'

/**
 * CtaButton component
 * 
 * @param {String} ctaText
 * @param {String} classModifier 
 * 
 * @returns JSX
 */
export default function CtaButton({ ctaText, classModifier }) {
  return (
    <div className={`cta ${classModifier ? `cta--${classModifier}` : ''}`}>
      <span className="cta__text">{ctaText}</span>
      <img src={Arrow} alt="Arrow" className="cta__arrow" />
    </div>
  )
}

CtaButton.propTypes = {
  ctaText: PropTypes.string,
  classModifier: PropTypes.string
}
