import { useEffect } from 'react'
import { navigate } from 'gatsby'

export function useNavigateLink(locationPath) {

  useEffect(() => {
    let timer = null
    // We pass to the navigate function state that is available to us in the location object. We are using that state to control the "curtain" transition effect for the pages
    // we need to use replace prop so we dont stack same loaction paths on link click and on new page load
    navigate(locationPath, {replace: true, state: { shouldAnimate: false }})
    
    // we must use this here, because on back arrow scroll is not set on top of the page
    window.scrollTo({
      top: 0,
      left: 0
    });

    timer = setTimeout(() => {
      const curtain = document.getElementById('curtain-animation');
      curtain.classList.remove('curtain--show')
    }, 1000)

    return () => clearTimeout(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}