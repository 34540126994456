import React from 'react'
import PropTypes from 'prop-types'

import JobsComponentItem from './JobsComponentItem'

import './JobsComponent.scss'

/**
 * WorkComponent
 * 
 * @param {Array} jobsRepeater
 * 
 * @returns {JSX Element}
 */
export default function JobsComponent({ jobsRepeater }) {
  return (
    <div className="jobs">
      <div className="content-wrapper">
        <div className="jobs__repeater">
          {
            jobsRepeater ? jobsRepeater.map((item, index) => {
              return (
                <JobsComponentItem 
                  key={index}
                  item={item} 
                />
              )
            }) : <p className="jobs__no-jobs">Sorry… No job openings.</p>
          }
        </div>
      </div>
    </div>
  )
}

JobsComponent.propTypes = {
  jobsRepeater: PropTypes.array
}