import React from 'react'

import CustomNavLink from './CustomNavLink'
import CtaButton from './CtaButton'
import './CtaMainButton.scss'

export default function CtaMainButton({ text }) {
  return (
    <div className="main-cta content-wrapper">
      <CustomNavLink path="/contact">
        <div className="main-cta__wrapper">
          <span className="main-cta__title">Get in touch</span>
          <CtaButton ctaText={text} />
        </div>
      </CustomNavLink>
    </div>
  )
}
