import React from 'react'
import PropTypes from 'prop-types'
import CustomNavLink from '../CustomNavLink'

/**
 * ContactFormConsent component
 * 
 * @param {String} type
 * @param {String} name 
 * @param {Function} register 
 * @param {Boolean} error  
 * 
 * @returns 
 */
export default function ContactFormConsent({ type, name, register, error }) {

  return (
    <div className="contact__form-consent">
      <div className={`contact__form-consent-wrapper ${error ? 'consent-error' : '' }`}>
        <div className="contact__form-consent-checkbox">
          <input 
            type={type} 
            {...register(name, {required: true})} 
            id={name} 
            name={name} 
          />
          <label htmlFor={name}>&nbsp;</label>
        </div>
        <div className="contact__form-consent-text">
        I consent to Breyta contacting me regarding the message I sent. Read more in our
        <CustomNavLink path="/privacy-policy" classes="contact__form-consent-link">
          Privacy policy.
        </CustomNavLink>
        </div>
      </div>
      {error}
    </div>
  )
}

ContactFormConsent.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  register: PropTypes.func,
  error: PropTypes.bool
}
