import React from 'react'
import PropTypes from 'prop-types'

import './SimpleTextComponent.scss'

/**
 * SimpleTextComponent
 * 
 * @param {String} param.title
 * @param {String} param.content
 * 
 * @returns {JSX}
 */
export default function SimpleTextComponent({title, content}) {
  
  return (
    <div className="simple-text">
      <div className="content-wrapper">
        <p className="simple-text__title">{`/${title}`}</p>
        <div className="simple-text__content">
          <p className="simple-text__content-text">{content}</p>
        </div>
      </div>
    </div>
  )
}

SimpleTextComponent.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired
}
