import React, {useEffect, useState, useRef} from 'react'
import PropTypes from 'prop-types'
import {GatsbyImage} from 'gatsby-plugin-image'

import CustomNavLink from './CustomNavLink'
import Arrow from '../svg-images/arrow.svg'

import useIntersect from '../hooks/useIntersect'
import {useCurrentWidth} from '../hooks/useResize'

import {applyCssProperties, calcImagePadding} from '../utils/utils'

/**
 * WorkComponentItem
 * 
 * @param {Object} param.item
 * @param {Number} param.index
 * @param {String} param.slug
 * @param {Function} param.handleMouseEnter
 * @param {Function} param.handleMouseLeave
 * @param {Function} param.handleMouseMove
 * @param {Function} param.setCursorStartingPoint
 * @param {Function} param.calcImagePadding
 * @param {Function} param.applyCssProperties
 * 
 * @returns {JSX Element}
 */
export default function WorkComponentItem({item, index, slug, handleMouseEnter, handleMouseLeave, handleMouseMove, setCursorStartingPoint}) {

  const {singleWork} = item

  const windowWidth = useCurrentWidth();

  const [isElementHovered, setIsElementHovered] = useState(false);
  const [cursorStartPosition, setCursorStartPosition] = useState({});

  const imageContainer = useRef(null);
  const imageWrapper = useRef(null);
  const imageOverlay = useRef(null);
  const title = useRef(null);
  const subtitle = useRef(null);
  const customCursor = useRef(null);
  const actionOverlay = useRef(null);


  const [ref, entry] = useIntersect({
    root: null,
    rootMargin: `0% 0% ${windowWidth < 1024 ? '-30%' : '-60%'}`
  });


  useEffect(() => {
    let timer = null;

    if(slug === 'work' && index === 0) {
      applyCssProperties(imageContainer, 'width', '100%');
      applyCssProperties(imageOverlay, 'width', '100%')
      applyCssProperties(title, 'transform', 'translateY(0px)');
      applyCssProperties(imageContainer, 'padding', calcImagePadding(windowWidth));
      
      timer = setTimeout(() => {
        if(imageOverlay.current.style.width === '100%') {
          applyCssProperties(imageOverlay, 'width', '0%')
          applyCssProperties(imageWrapper, 'transform', 'scale(1)')
          applyCssProperties(subtitle, 'transform', 'translateY(0px)')
        }
      }, 400)
    }
    return () => clearTimeout(timer)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    let timer = null;

    if(entry.isIntersecting && (imageContainer.current.style.width === '' || imageOverlay.current.style.width === '100%')) {
      applyCssProperties(imageContainer, 'width', '100%');
      applyCssProperties(imageOverlay, 'width', '100%')
      applyCssProperties(title, 'transform', 'translateY(0px)');
      applyCssProperties(imageWrapper, 'transform', 'scale(1.3)')
      applyCssProperties(imageContainer, 'padding', calcImagePadding(windowWidth));
      
      timer = setTimeout(() => {
        applyCssProperties(imageOverlay, 'width', '0%')
        applyCssProperties(imageWrapper, 'transition', 'transform .8s ease')
        applyCssProperties(imageWrapper, 'transform', 'scale(1)')
        applyCssProperties(subtitle, 'transform', 'translateY(0px)')
      }, 300)
    }
    return () => clearTimeout(timer)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entry.isIntersecting])

  useEffect(() => {
    if(isElementHovered) {
      imageWrapper.current.style.transition !== 'transform .3s ease' && applyCssProperties(imageWrapper, 'transition', 'transform .3s ease')
      applyCssProperties(imageWrapper, 'transform', 'scale(1.1)')
    }
    else {
      applyCssProperties(imageWrapper, 'transform', 'scale(1)')
    }
  }, [isElementHovered])

  // setting padding on window resize
  useEffect(() => {
    if(imageContainer.current.style.width === '100%'){
      imageContainer.current.style.padding = calcImagePadding(windowWidth)
    } 
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowWidth])


  return (
    <div className="work-component__repeater-item" ref={ref}>
      <CustomNavLink classes="work-component__reperater-link" path={singleWork.uri}>
        <div className={`work-component__repeater-img-container ${
            isElementHovered ? 'work-component__repeater-img-container--hover' : ''
          }`} 
          ref={imageContainer}
        >
          <div className="work-component__repeater-img-wrapper-parent">
            <div ref={imageWrapper} 
              className="work-component__repeater-img-wrapper"
            >
              <GatsbyImage
                image={singleWork.featuredImage.node.localFile.childImageSharp.gatsbyImageData} 
                alt={singleWork.featuredImage.node.altText}
                className={`work-component__repeater-img`}
              />
            <div ref={imageOverlay} className="work-component__repeater-img-overlay"></div>
            </div>
          </div>
          <div 
            className="work-component__repeater-action-overlay"
            onMouseEnter={(e) => {
              handleMouseEnter(isElementHovered, setIsElementHovered, imageWrapper)
              setCursorStartingPoint(e, imageContainer, setCursorStartPosition)
            }}
            onMouseMove={(e) => handleMouseMove(e, imageContainer, customCursor, isElementHovered)}
            onMouseLeave={() => handleMouseLeave(isElementHovered, setIsElementHovered)}
            aria-hidden="true"
            ref={actionOverlay}
          ></div>
            {
              isElementHovered &&
              <div 
                className="work-component__repeater-cursor" 
                ref={customCursor}
                style={{
                  transform: `translate(${cursorStartPosition.x}px, ${cursorStartPosition.y}px) scale(0)`
                }}
              >
                <Arrow className="work-component__repeater-cursor-arrow"/>
              </div>
            }
        </div>
      </CustomNavLink>
      <h2 ref={title} className="work-component__repeater-item-title">{singleWork.title}</h2>
      <p ref={subtitle} className="work-component__repeater-item-subtitle">
        {
          singleWork.categories?.nodes &&
          singleWork.categories.nodes.map((item, index) => {
            return `${item.name} ${index !== singleWork.categories.nodes.length - 1 ? '/' : ''} `
          })
        }
      </p>
    </div>
  )
}

WorkComponentItem.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  slug: PropTypes.string.isRequired,
  handleMouseEnter: PropTypes.func.isRequired,
  handleMouseLeave: PropTypes.func.isRequired,
  handleMouseMove: PropTypes.func.isRequired,
  setCursorStartPosition: PropTypes.func
}