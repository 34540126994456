import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {GatsbyImage} from 'gatsby-plugin-image'

import useIntersect from '../hooks/useIntersect'

import './SingleImage.scss'

/**
 * SingleImage
 * 
 * @param {Object} param.image 
 * 
 * @returns {JSX}
 */
export default function SingleImage({image}) {

  const [isInView, setIsInView] = useState(false)

  const [ref, entry] = useIntersect({
    root: null,
    rootMargin: '0% 0% -20%'
  })

  useEffect(() => {
    if(entry.isIntersecting) {
      !isInView && setIsInView(true);
    }
  }, [entry.isIntersecting, isInView])
  
  return (
    <div className="single-image" ref={ref}>
      <div className="content-wrapper">
        <div className={`single-image__wrapper ${isInView ? 'single-image__wrapper--in-view' : ''}`}>
          <GatsbyImage 
            className="single-image__image" 
            alt={image.altText ? image.altText : ''} 
            image={image.localFile.childImageSharp.gatsbyImageData}
          />
        </div>
      </div>
    </div>
  )
}

SingleImage.propTypes = {
  image: PropTypes.object.isRequired
}