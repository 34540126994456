import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'


import PageHero from '../components/PageHero'
import CtaMainButton from '../components/CtaMainButton'
import ExpertiseComponent from '../components/ExpertiseComponent'
import WorkComponent from '../components/WorkComponent'
import JobsComponent from '../components/JobsComponent'
import SimpleTextComponent from '../components/SimpleTextComponent'
import ServicesComponent from '../components/ServicesComponent'
import FullWidthImage from '../components/FullWidthImage'
import TwoColumnText from '../components/TwoColumnText'
import Contact from '../components/form/Contact'
import WhatWeDoComponent from '../components/WhatWeDoComponent'
import SingleImage from '../components/SingleImage'
import PrivacyPolicyContent from '../components/PrivacyPolicyContent'
import OurTeamComponent from '../components/OurTeamComponent'
import Layout from '../components/Layout'

import { useNavigateLink } from '../hooks/useNavigateLink'

export default function Page({ data, location }) {
  
  const { state } = location
  
  const { wpPage: { contactForm: { contactFormEmail }, pageFlexibleContent: { flexibleContent }, slug, isFrontPage, title }, allWpMenu: {menus}, allWp: { nodes } } = data
  const { acfOptionsOptionsPage: { optionsPage: { copyrightField } },  generalSettings: { url } } = nodes[0]
  
  const mainMenu = menus.filter(menu => menu.locations[0] === 'MENU_1');
  const footerMenu = menus.filter(menu => menu.locations[0] === 'FOOTER_MENU');

  // Controls curtain transition
  useNavigateLink(location.pathname)
 
  return (
    <Layout mainMenu={mainMenu} footerMenu={footerMenu} copyrightField={copyrightField} shouldAnimate={state?.shouldAnimate} isHomePage={isFrontPage} title={title}>
      <>
        {
          flexibleContent.map((component, index) => {
            switch (component.fieldGroupName) {
              case "Page_Pageflexiblecontent_FlexibleContent_Hero":
                return <PageHero slug={slug} title={component.heroTitle} subtitleSection={component.subtitleSection} copyright={copyrightField} key={index} />
              case "Page_Pageflexiblecontent_FlexibleContent_Expertise":
                return <ExpertiseComponent image={component.image} text={component.text} key={index} />
              case "Page_Pageflexiblecontent_FlexibleContent_Work":
                return <WorkComponent workRepeater={component.workRepeater} key={index} slug={slug}/>
              case "Page_Pageflexiblecontent_FlexibleContent_Jobs":
                return <JobsComponent jobsRepeater={component.jobsRepeater} key={index} slug={slug} />
              case "Page_Pageflexiblecontent_FlexibleContent_SimpleTextComponent":
                return <SimpleTextComponent title={component.sectionTitle} content={component.sectionContent} key={index}/>
              case "Page_Pageflexiblecontent_FlexibleContent_Services":
                return <ServicesComponent servicesRepeater={component.servicesRepeater} key={index}/>
              case "Page_Pageflexiblecontent_FlexibleContent_TwoColumnsTitleText":
                return <TwoColumnText label={component.label} title={component.title} content={component.content} key={index}/>
              case "Page_Pageflexiblecontent_FlexibleContent_ContactInfo":
                return <Contact image={component.image} infoRepeater={component.infoRepeater} key={index} backendUrl={url} email={contactFormEmail} />
              case "Page_Pageflexiblecontent_FlexibleContent_WhatWeDo":
                return <WhatWeDoComponent title={component.title} content={component.content} serviceRepeater={component.serviceRepeater} key={index}/>
              case "Page_Pageflexiblecontent_FlexibleContent_CtaContact":
                return <CtaMainButton text={component.contactText} key={index} />
              case "Page_Pageflexiblecontent_FlexibleContent_FullWidthImage":
                return <FullWidthImage image={component.image} key={index}/>
              case "Page_Pageflexiblecontent_FlexibleContent_SingleImage":
                return <SingleImage image={component.image} key={index}/>
              case "Page_Pageflexiblecontent_FlexibleContent_OurTeam":
                return <OurTeamComponent teamRepeater={component.teamRepeater} key={index}/>
              case "Page_Pageflexiblecontent_FlexibleContent_PrivacyPolicyContent":
                return <PrivacyPolicyContent content={component.content} key={index}/>
              default:
                return null
            }
          })
        }
      </>
    </Layout>
  )
}

Page.propTypes = {
  data: PropTypes.object.isRequired
}
export const flexibleContentQuery = graphql`query pageByID($id: String!) {
  allWp {
    nodes {
      acfOptionsOptionsPage {
        optionsPage {
          copyrightField
        }
      }
      generalSettings {
        url
      }
    }
  }
  allWpMenu {
    ...getMenus
  }
  wpPage(id: {eq: $id}) {
    title
    slug
    uri
    isFrontPage
    contactForm {
      contactFormEmail
    }
    pageFlexibleContent {
      flexibleContent {
        ... on WpPage_Pageflexiblecontent_FlexibleContent_Hero {
          fieldGroupName
          heroTitle
          subtitleSection {
            rightOrUnder
            subtitle
          }
        }
        ... on WpPage_Pageflexiblecontent_FlexibleContent_Expertise {
          fieldGroupName
          text
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
        ... on WpPage_Pageflexiblecontent_FlexibleContent_Services {
          fieldGroupName
          servicesRepeater {
            fieldGroupName
            serviceText
            serviceTitle
            serviceImage {
              sourceUrl
              altText
            }
          }
        }
        ... on WpPage_Pageflexiblecontent_FlexibleContent_Work {
          fieldGroupName
          workRepeater {
            singleWork {
              ... on WpWork {
                id
                uri
                title
                content
                categories {
                  nodes {
                    name
                    slug
                  }
                }
                featuredImage {
                  node {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          layout: FULL_WIDTH
                        )
                      }
                    }
                  }
                }
              }
            }
          }
        }
        ... on WpPage_Pageflexiblecontent_FlexibleContent_Jobs {
          fieldGroupName
          jobsRepeater {
            singleJob {
              ... on WpJob {
                id
                uri
                title
                singleJobContent {
                  jobHeroSubtitle {
                    position
                  }
                  jobWysiwyg
                }
              }
            }
          }
        }
        ... on WpPage_Pageflexiblecontent_FlexibleContent_FullWidthImage {
          fieldGroupName
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
        ... on WpPage_Pageflexiblecontent_FlexibleContent_SimpleTextComponent {
          fieldGroupName
          sectionContent
          sectionTitle
        }
        ... on WpPage_Pageflexiblecontent_FlexibleContent_SingleImage {
          fieldGroupName
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
        ... on WpPage_Pageflexiblecontent_FlexibleContent_TwoColumnsTitleText {
          content
          fieldGroupName
          label
          title
        }
        ... on WpPage_Pageflexiblecontent_FlexibleContent_WhatWeDo {
          content
          fieldGroupName
          title
          serviceRepeater {
            fieldGroupName
            serviceTitle
            serviceIcon {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                  )
                }
              }
            }
            subServices {
              fieldGroupName
              singleSubService
            }
          }
        }
        ... on WpPage_Pageflexiblecontent_FlexibleContent_OurTeam {
          fieldGroupName
          teamRepeater {
            fieldGroupName
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                  )
                }
              }
            }
            name
            position
          }
        }
        ... on WpPage_Pageflexiblecontent_FlexibleContent_CtaContact {
          fieldGroupName
          contactText
        }
        ... on WpPage_Pageflexiblecontent_FlexibleContent_Wysiwyg {
          contentComponent
          fieldGroupName
        }
        ... on WpPage_Pageflexiblecontent_FlexibleContent_ContactInfo {
          fieldGroupName
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                )
              }
            }
          }
          infoRepeater {
            infoTitle
            infos {
              singleInfo
              singleInfoType
            }
          }
        }
        ... on WpPage_Pageflexiblecontent_FlexibleContent_PrivacyPolicyContent {
          fieldGroupName
          content
        }
      }
    }
  }
}
`