import React from 'react'
import PropTypes from 'prop-types'

import ErrorImage from '../../images/errorDialog.svg'
import './ErrorDialogBox.scss'

/**
 * ErrorDialogBox component
 * 
 * @param {Function} onClose 
 * 
 * @returns 
 */
export default function ErrorDialogBox({ onClose }) {

  return (
    <div className="dialog-box">
      <div className="dialog-box__wrapper">
        <button className="dialog-box__close-btn" aria-label="close" onClick={onClose}></button>
        <div className="dialog-box__content">
          <div className="dialog-box__image-wrapper">
            <img className="dialog-box__image" src={ErrorImage} alt="Error" />
          </div>
          <div className="dialog-box__content-box">
            <h3 className="dialog-box__title">Error: Server not responding!</h3>
            <p className="dialog-box__text">There was a problem sending your request. Please try again.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

ErrorDialogBox.propTypes = {
  onClose: PropTypes.func,
}
