import React from 'react'
import PropTypes from 'prop-types'

import CustomNavLink from './CustomNavLink'
import CtaButton from './CtaButton'

/**
 * JobsComponentItem
 * 
 * @param {Object} item
 * 
 * @returns {JSX Element}
 */
export default function JobsComponentItem({ item }) {

  const { singleJob: { title, uri, singleJobContent: { jobHeroSubtitle: { position } }} } = item

  return (
    <CustomNavLink path={uri}>
      <div className="jobs__job">
        <div className="jobs__job-content">
          <h2 className="jobs__job-title">{title}</h2>
          <p className="jobs__job-subtitle">{position}</p>
        </div>
        <CtaButton 
          ctaText="See details"
        />
      </div>
    </CustomNavLink>
  )
}

JobsComponentItem.propTypes = {
  item: PropTypes.object.isRequired,
}