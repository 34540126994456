import React from 'react'
import PropTypes from 'prop-types'

import ContactForm from './ContactForm'

import './Contact.scss'

/**
 * Contact component
 * 
 * @param {Array} infoRepeater
 * @param {Object} image 
 * @param {String} backendUrl
 * @param {String} email
 * 
 * @returns 
 */
export default function Contact({ infoRepeater, image, backendUrl, email }) {

  return (
    <div className="contact">
      <div className="contact__wrapper content-wrapper">
        <ContactForm backendUrl={backendUrl} infoRepeater={infoRepeater} image={image} email={email} />
      </div>
    </div>
  )
}

Contact.propTypes = {
  infoRepeater: PropTypes.array,
  image: PropTypes.object,
  backendUrl: PropTypes.string,
  email: PropTypes.string.isRequired
}
