import React, {useState, useEffect, useRef, forwardRef} from 'react'
import PropTypes from 'prop-types'

import {getMousePosition} from '../utils/utils'

/**
 * ServicesComponentItems component
 * 
 * @param {object} param.item
 * @param {number} param.index
 * @param {Function} param.calcViewBox
 * @param {Function} param.handleMouseIn
 * @param {Function} param.handleMouseOut
 * @param {Function} param.handleMouseMove
 * @param {number} param.windowWidth
 * @param {Function} param.setCursorStartingPoint
 * @param {Function} param.updateImagePosition
 * @param {Function} param.distance
 * @param {Function} param.getImagePosition
 * @param {ref} param.forwardRef - forwarding ref to parent component
 * 
 * @returns {JSX Element}
 */
const ServicesComponentItems = forwardRef(({item, index, handleMouseIn, handleMouseOut, windowWidth, setCursorStartingPoint, updateImagePosition, distance, getImagePosition, elementDisplacement}, forwardRef) => {

  const [isElementHovered, setIsElementHovered] = useState(false);
  const [cursorStartPosition, setCursorStartPosition] = useState({});

  const displacementMap = useRef(null);
  const imageRef = useRef(null);
  const containerRef = useRef(null);

  let animationID = useRef(null);
  
  const prevMousePosition = {
    x: null,
    y: null
  }

  /**
   * handleMouseMove
   * 
   * Handles animations on mouse move inside a container
   * @param {Object} mousePos 
   * @param {Ref} container 
   * @param {Ref} image 
   * @param {Ref} displacementMap 
   * @param {Boolean} isElementHovered 
   */
  const handleMouseMove = (mousePos, container, image, displacementMap, isElementHovered) => {
    if(isElementHovered && windowWidth > 1023) {
      // getting image and cursor position
      const cursorPos = getMousePosition(mousePos, container)
      const imagePos = getImagePosition(image, container)

      // setting previous mouse position and updating image to this position. On mount position is where mouse enters container
      prevMousePosition.x = prevMousePosition.x !== null ? elementDisplacement(prevMousePosition.x, cursorPos.x, 0.1) : cursorPos.x
      prevMousePosition.y = prevMousePosition.y !== null ? elementDisplacement(prevMousePosition.y, cursorPos.y, 0.1) : cursorPos.y

      updateImagePosition(image, prevMousePosition)
      
      // calculating distance between mouse and image and setting it as a distortion value
      const mouseDistance = Math.min(distance(imagePos, cursorPos), 80)
      displacementMap.current.scale.baseVal = mouseDistance
      
      // recursive loop until image and cursor are on the same position
      if(Math.round(mouseDistance) !== 0) {
        animationID.current = requestAnimationFrame(() => handleMouseMove(mousePos, container, image, displacementMap, isElementHovered))
      }
    }
  }

  
  useEffect(() => {
    if(windowWidth < 1024) {
      setIsElementHovered(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowWidth])
  

  useEffect(() => {
    const container = containerRef.current

    const callEvent = (e) => {
      e.preventDefault()

      const mousePos = {
        clientX: e.clientX,
        clientY: e.clientY
      }

      cancelAnimationFrame(animationID.current)
      animationID.current = requestAnimationFrame(() => handleMouseMove(mousePos, containerRef, imageRef, displacementMap, isElementHovered))
    }
    
    if(isElementHovered && windowWidth > 1023) {   
      container.addEventListener('mousemove', (e) => callEvent(e), false)
    }
    
    // clean up
    return () => {
      container.removeEventListener('mousemove', (e) => callEvent(e), false)
      cancelAnimationFrame(animationID.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isElementHovered])

  return(
    <div className="services__repeater-wrapper">
      <span className={`services__repeater-number ${
        isElementHovered ? 'services__repeater-number--on-hover' : ''
      }`}
      >
        {`0${index + 1}`}
      </span>
      <div className="services__repeater-content-wrapper" ref={forwardRef}>
        <div className="services__repeater-content" 
          onMouseEnter={(e) => {
            windowWidth > 1023 && handleMouseIn(isElementHovered, setIsElementHovered)
            setCursorStartingPoint(e, containerRef, setCursorStartPosition)
          }}
          onMouseOut={() => handleMouseOut(isElementHovered, setIsElementHovered, imageRef)}
          onBlur={() => handleMouseOut(isElementHovered, setIsElementHovered)}
          aria-hidden="true"
          ref={containerRef}
        >
          <div className="services__repeater-img" 
            ref={imageRef}
            style={{
              display: `${isElementHovered ? 'block' : 'none'}`,
              animationName: 'fadeInToPosition',
              animationDuration: '.5s',
              animationFillMode: 'forwards',
              transform: `translate(${cursorStartPosition.x}px, ${cursorStartPosition.y}px)`
            }}
          >
            <svg className="services__svg-box">
              <defs>
                <filter id={`distortionFilter--${index}`}>
                  <feTurbulence type="fractalNoise" baseFrequency="0.01 0.003" numOctaves="5" seed="2" stitchTiles="noStitch" x="0%" y="0%" width="100%" height="100%" result="noise"/>
                  <feDisplacementMap ref={displacementMap} in="SourceGraphic" in2="noise"  xChannelSelector="R" yChannelSelector="B" x="0%" y="0%" width="100%" height="100%" filterUnits="userSpaceOnUse"/>
                </filter>
              </defs>
              <g>
                <image xlinkHref={item.serviceImage && item.serviceImage.sourceUrl} 
                  style={{filter: `url(#distortionFilter--${index})`}}
                  x="50" y="50"
                />
              </g>
            </svg>
          </div>
          <h2 className="services__repeater-title">{item.serviceTitle}</h2>
          <p className={`services__repeater-text ${isElementHovered && windowWidth > 1023 ? 'services__repeater-text--hover' : ''}`}>{item.serviceText}</p>
        </div>
      </div>
    </div>
  )
})

ServicesComponentItems.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  handleMouseIn: PropTypes.func.isRequired,
  handleMouseOut: PropTypes.func.isRequired,
  windowWidth: PropTypes.number.isRequired,
  setCursorStartingPoint: PropTypes.func.isRequired,
  updateImagePosition: PropTypes.func.isRequired,
  distance: PropTypes.func.isRequired,
  getImagePosition: PropTypes.func.isRequired,
  elementDisplacement: PropTypes.func.isRequired
}

export default ServicesComponentItems