import React from 'react'
import PropTypes from 'prop-types'

import WorkComponentItem from './WorkComponentItem'

import {throttle} from 'lodash'
import {getMousePosition} from '../utils/utils'

import './WorkComponent.scss'

/**
 * WorkComponent
 * 
 * @param {Array} param.workRepeater
 * @param {String} param.slug
 * 
 * @returns {JSX Element}
 */
export default function WorkComponent({workRepeater, slug}) {

  // radius of custom cursor(always the same)
  const cursorRadius = 42

  
  /**
   * setCursorStartingPoint
   * 
   * sets the starting point of custom cursor(where mouse enters the image ref)
   * @param {Event} e 
   * @param {Ref} image 
   * @param {Function} setState 
   */
  const setCursorStartingPoint = (e, image, setState) => {
    const cursorPos = getMousePosition(e, image)
    setState({x: cursorPos.x - cursorRadius, y: cursorPos.y - cursorRadius});
  }
  
  /**
   * updateCursor
   * 
   * updates cursor position on mouse move useing throttle function for safari purpose
   * @param {Event} e
   * @param {Ref} container
   * @param {Ref} customCursor
   */
  const updateCursor = throttle((e, container, customCursor) => {
    const cursorPos = getMousePosition(e, container);
    if(customCursor.current) {
      customCursor.current.style.transform = `translate(${
        cursorPos.x - cursorRadius}px, ${
        cursorPos.y - cursorRadius}px)`
    }
  }, 50)


  /**
   * handleMouseEnter
   * 
   * Updates the state on mouse enter
   * @param {Boolean} state 
   * @param {Function} setState 
   */
  const handleMouseEnter = (state, setState) => !state && setState(true)

  /**
   * handleMouseLeave
   * 
   * Updates the state on mouse leave
   * @param {Boolean} state 
   * @param {Function} setState 
   */
  const handleMouseLeave = (state, setState) => state && setState(false)

  /**
   * handleMouseMove
   * 
   * Executes on every mouse move inside container
   * @param {Event} e 
   * @param {Ref} container 
   * @param {Ref} customCursor 
   * @param {Boolean} isElementHovered 
   */
  const handleMouseMove = (e, container, customCursor, isElementHovered) => {
    if(isElementHovered) {
      updateCursor(e, container, customCursor)
    }
  }

  return (
    <div className={`work-component--${slug}`}>
      <div className="content-wrapper">
        {
          slug === 'homepage' &&
          <p className="work-component__label">/WORK</p>
        }
        <div className="work-component__repeater">
          {
            workRepeater.map((item, index) => {
              return (
                <WorkComponentItem 
                  item={item} 
                  index={index}
                  slug={slug}
                  handleMouseEnter={handleMouseEnter}
                  handleMouseLeave={handleMouseLeave}
                  handleMouseMove={handleMouseMove}
                  setCursorStartingPoint={setCursorStartingPoint}
                  key={index}
                />
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

WorkComponent.propTypes = {
  workRepeater: PropTypes.array.isRequired,
  slug: PropTypes.string.isRequired
}