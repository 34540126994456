import React from 'react'
import PropTypes from 'prop-types'
import TextareaAutosize from 'react-textarea-autosize';

/**
 * InputField component
 * 
 * @param {String} type
 * @param {String} name 
 * @param {String} label 
 * @param {Function} register 
 * @param {Element} error 
 * @param {Boolean} isRequierdField - optional(if field is required add red star) - default value true
 * 
 * @returns 
 */
export default function InputField({ type, name, label, register, error, isRequierdField = true }) {

  const errorClassName = error ? 'error' : '' 
  
  return (
    <div className="contact__form-group">
      {
        (() => {
          switch(type) {
            case 'text':
              return(
                <>
                  {isRequierdField ? 
                    <input 
                      {...register(name, 
                        { 
                          required: 'Name is required',
                          pattern: {
                            value: /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ÐðđĐÞþÆæ ,.'-]+$/u,
                            message: 'Enter a valid name'
                          }
                        }
                      )} 
                      className={errorClassName}
                      name={name} 
                      id={name} 
                      required
                    /> : 
                    <input 
                      {...register(name)}
                      name={name} 
                      id={name} 
                      required
                    /> 
                  }
                </> 
              )
            case 'email':
              return(
                <input {...register(name, 
                  {
                    required: "Email is required", 
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Please enter valid email"
                    }
                  })} 
                  className={errorClassName}
                  name={name}
                  id={name} 
                  required
                />
                
              )
            case 'textarea':
              return(
                <TextareaAutosize 
                  {...register(name, { required: 'Message is required' })} 
                  className={errorClassName}
                  maxRows={9}
                  name={name} 
                  id={name}
                  required
                />
              )
            default:
              return null  
          }
        })()
      }
      <span className="focus-border"></span>
      <label htmlFor={name}>{label}{isRequierdField ? <span className="star">*</span> : ''}</label>
      {error}
    </div>
  )
}

InputField.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  register: PropTypes.func,
  error: PropTypes.element,
  isRequierdField: PropTypes.bool,
}