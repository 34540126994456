import React, {useEffect, useRef} from 'react'
import PropTypes from 'prop-types'
import {GatsbyImage} from 'gatsby-plugin-image'

import useIntersect from '../hooks/useIntersect'
import {useCurrentWidth} from '../hooks/useResize'

import {applyCssProperties, calcImagePadding} from '../utils/utils'

/**
 * OurTeamMember
 * 
 * @param {Object} param.teamMember
 * 
 * @returns {JSX}
 */
export default function OurTeamMember({teamMember}) {

  const windowWidth = useCurrentWidth();

  const imageWrapper = useRef(null)
  const overlay = useRef(null)
  const title = useRef(null)
  const position = useRef(null)
  const image = useRef(null)

  const [ref, entry] = useIntersect(({
    root: null,
    rootMargin: '0% 0% -50%'
  }))


  useEffect(() => {
    let timer = null
    if(entry.isIntersecting && (imageWrapper.current.style.width === '' || overlay.current.style.width === '100%')) {
      applyCssProperties(imageWrapper, 'width', '100%')
      applyCssProperties(overlay, 'width', '100%')
      applyCssProperties(imageWrapper, 'padding', calcImagePadding(windowWidth))
      applyCssProperties(title, 'transform', 'translateY(0px)')
      
      timer = setTimeout(() => {
        applyCssProperties(image, 'transform', 'scale(1)')
        applyCssProperties(overlay, 'width', '0%')
        applyCssProperties(position, 'transform', 'translateY(0px)')
      }, 400)
    }
    return () => clearTimeout(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entry.isIntersecting, windowWidth])

  useEffect(() => {
    if(entry.isIntersecting) {
      applyCssProperties(imageWrapper, 'padding', calcImagePadding(windowWidth))
    }
  }, [entry.isIntersecting, windowWidth])
  
  return (
    <div className="our-team__member" ref={ref}>
      <div className="our-team__member-image-wrapper" ref={imageWrapper}>
        <div className="our-team__member-image-container-zoom">
          <div className="our-team__member-image-container" ref={image}>
            <GatsbyImage 
              image={teamMember.image.localFile.childImageSharp.gatsbyImageData}
              alt={teamMember.image.altText ? teamMember.image.altText : 'Teammate'}
              className="our-team__member-image"
            />
          </div>
        </div>
        <div className="our-team__member-image-overlay" ref={overlay}></div>
      </div>
      <div className="our-team__member-info">
        <h3 className="our-team__member-name" ref={title}>{teamMember.name}</h3>
        <p className="our-team__member-position" ref={position}>{teamMember.position}</p>
      </div>
    </div>
  )
}

OurTeamMember.propTypes = {
  teamMember: PropTypes.object.isRequired
}