import React from 'react'
import PropTypes from 'prop-types'

import WhatWeDoComponentItem from './WhatWeDoComponentItem'
// scss
import './WhatWeDoComponent.scss'
// svg
import Rune from '../svg-images/rune.svg'
import B from '../svg-images/b.svg'

/**
 * WhatWeDoComponent
 * 
 * @param {String} param.title
 * @param {String} param.content
 * @param {Array} param.serviceRepeater
 * @returns {JSX}
 */
export default function WhatWeDoComponent({title, content, serviceRepeater}) {
  
  return (
    <div className="what-we-do">
      <div className="content-wrapper">
        <div className="what-we-do__headlines-wrapper">
          <div className="what-we-do__headlines">
            <p className="what-we-do__headlines-label">/WHAT WE DO</p>
            <h2 className="what-we-do__headlines-title">{title}</h2>
            <p className="what-we-do__headlines-content">{content}</p>
            <div className="what-we-do__headlines-rune-wrapper"><Rune className="what-we-do__headlines-rune"/></div>
          </div>
          <B className="what-we-do__headlines-b"/>
        </div>
        <div className="what-we-do__repeater">
          {
            serviceRepeater.map((item, index) => {
              return (
                <WhatWeDoComponentItem item={item} key={index}/>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

WhatWeDoComponent.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  serviceRepeater: PropTypes.array.isRequired
}