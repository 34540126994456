import React from 'react'
import PropTypes from 'prop-types'

import './PrivacyPolicyContent.scss'

/**
 * PrivacyPolicyContent component
 * 
 * @param {String} content 
 * 
 * @returns 
 */
export default function PrivacyPolicyContent({ content }) {

  return (
    <div className="privacy-policy content-wrapper">
      <div className="privacy-policy__wrapper">  
        <div className="privacy-policy__content" dangerouslySetInnerHTML={{ __html: content }}/>
      </div>
    </div>
  )
}

PrivacyPolicyContent.propTypes = {
  content: PropTypes.string
}