import React from 'react'
import PropTypes from 'prop-types'
import { getImage } from 'gatsby-plugin-image';
import BackgroundImage from 'gatsby-background-image'

import { convertToBgImage } from '../utils/utils.js'
import Rune from '../svg-images/rune.svg'
import './ExpertiseComponent.scss'


/**
* ExpertiseComponent component
* @param {Object} param.image
* @param {String} param.text
*
* @returns {JSX Element}
*/

export default function ExpertiseComponent({ image, text }) {

  const expertiseImage = getImage(image.localFile.childImageSharp.gatsbyImageData)

  const bgImage = convertToBgImage(expertiseImage)

  return (
    <div className="expertise content-wrapper">
      <Rune className="expertise__rune" />
      {
        image &&
        <BackgroundImage
          Tag="section"
          {...bgImage}
          preserveStackingContext
          className="expertise__img"
          alt={image.altText ? image.altText : 'Expertise'}
        />
      }
      <div className="expertise__content">
        <span className="expertise__content-label">/Expertise</span>
        <p className="expertise__content-text">{text}</p>
      </div>
    </div>
  );
}

ExpertiseComponent.propTypes = {
  image: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired
}