import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {GatsbyImage} from 'gatsby-plugin-image'

import useIntersect from '../hooks/useIntersect'

/**
 * WhatWeDoComponentItem
 * 
 * @param {Object} param.item
 * @returns {JSX}
 */
export default function WhatWeDoComponentItem ({item}) {

  const [isInView, setIsInView] = useState(false)

  const [ref, entry] = useIntersect({
    root: null,
    rootMargin: '0% 0% 0%'
  })

  const halfArrayLength = Math.ceil(item.subServices.length / 2);
  const subServicesLeft = item.subServices.slice(0, halfArrayLength)
  const subServicesRight = item.subServices.slice(halfArrayLength, item.subServices.length)


  useEffect(() => {
    if(entry.isIntersecting) {
      setIsInView(true)
    }
  }, [entry.isIntersecting, isInView])

  
  return (
    <div className={`what-we-do__repeater-item ${isInView ? 'what-we-do__repeater-item--in-view' : ''}`} ref={ref}>
      <div className="what-we-do__repeater-item-headlines">
        {
          item.serviceIcon &&
          <GatsbyImage 
            className="what-we-do__repeater-item-headlines-icon" 
            image={item.serviceIcon.localFile.childImageSharp.gatsbyImageData}
            alt={item.serviceIcon.altText ? item.serviceIcon.altText : ''}
          />
        }
        <p className="what-we-do__repeater-item-headlines-title">{item.serviceTitle}</p>
      </div>
      <div className="what-we-do__repeater-item-list">
        <div className="what-we-do__repeater-item-list-left">
          {
            subServicesLeft.map((item, index) => {
              return (
                <p className="what-we-do__repeater-item-list-item" key={index}>{item.singleSubService}</p>
              )
            })
          }
        </div>
        <div>
          {
            subServicesRight.map((item, index) => {
              return (
                <p className="what-we-do__repeater-item-list-item" key={index}>{item.singleSubService}</p>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}


WhatWeDoComponentItem.propTypes = {
  item: PropTypes.object.isRequired
}