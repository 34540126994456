import React from 'react'
import PropTypes from 'prop-types'

import OurTeamMember from './OurTeamMember'

import './OurTeamComponent.scss'

/**
 * OurTeamComponent
 * 
 * @param {Array} param.teamRepeater
 * 
 * @returns {JSX}
 */
export default function OurTeamComponent({teamRepeater}) {
  
  return (
    <div className="our-team">
      <div className="content-wrapper">
        <p className="our-team__label">/OUR TEAM</p>
        <div className="our-team__repeater">
          {
            teamRepeater.map((teamMember, index) => {
              return(
                <OurTeamMember teamMember={teamMember} key={index}/>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

OurTeamComponent.propTypes = {
  teamRepeater: PropTypes.array.isRequired
}