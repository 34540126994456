import React from 'react'
import PropTypes from 'prop-types'

import './TwoColumnText.scss'

/**
 * TwoColumnText
 * 
 * @param {String} param.label 
 * @param {String} param.title
 * @param {String} param.content
 * @returns {JSX}
 */
export default function TwoColumnText({label, title, content}) {
  
  return (
    <div className="two-column">
      <div className="content-wrapper">
        <p className="two-column__label">{`/${label}`}</p>
        <div className="two-column__content">
          <h2 className="two-column__content-title">{title}</h2>
          <div className="two-column__content-text-container">
            <div className="two-column__content-text" dangerouslySetInnerHTML={{__html: content}}></div>
          </div>
        </div>
      </div>
    </div>
  )
}

TwoColumnText.propTypes = {
  label: PropTypes.string.isRequired,
  title: PropTypes.string,
  content: PropTypes.string.isRequired
}