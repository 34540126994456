import React, {useRef} from 'react'
import PropTypes from 'prop-types'
import {GatsbyImage} from 'gatsby-plugin-image'

import useSmoothScroll from '../hooks/useSmoothScroll'
import useIntersect from '../hooks/useIntersect'

import {useCurrentHeight} from '../hooks/useResize'

import './FullWidthImage.scss'

/**
 * FullWidthImage
 * 
 * @param {Object} param.image
 * @returns {JSX}
 */
export default function FullWidthImage({image}) {

  const imageWrapper = useRef(null);

  const windowHeight = useCurrentHeight();

  const [ref, entry] = useIntersect({
    root: null,
    rootMargin: '0px 0px 0px'
  })

  /**
   * calcElementMovementOnScroll
   * 
   * @param {Number} smoothScrollPos
   * @returns {Number} movement of elemnt on scroll
   */
  const calcElementMovementOnScroll = (smoothScrollPos) => Math.abs((smoothScrollPos - (imageWrapper.current?.offsetTop - windowHeight)) / 4.5)

  useSmoothScroll({
    element: imageWrapper,
    ease: 0.08,
    isInView: entry.isIntersecting,
    calcElementMovementOnScroll: calcElementMovementOnScroll
  })
  
  return (
    <div className="full-width-image" ref={ref}>
      {
        image &&
          <div className="full-width-image__image-container">
            <div className="full-width-image__image-wrapper" ref={imageWrapper}>
              <GatsbyImage 
                className="full-width-image__image"
                image={image.localFile.childImageSharp.gatsbyImageData} 
                alt={image.altText ? image.altText : ''}
              />
            </div>
          </div>
      }
    </div>
  )
}

FullWidthImage.propTypes = {
  image: PropTypes.object.isRequired
}