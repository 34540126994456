import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'

import { navigate } from 'gatsby'
import { fetchWithTimeout } from '../../utils/utils'
import ContactInfo from './ContactInfo'
import CtaShuffle from '../CtaShuffle'
import InputField from './InputField'
import ContactFormConsent from './ContactFormConsent'
import ErrorDialogBox from './ErrorDialogBox'

/**
 * ContactForm component
 * 
 * @param {String} backendUrl
 * @param {Array} infoRepeater 
 * @param {Object} image 
 * @param {String} email
 * 
 * @returns 
 */
export default function ContactForm({ backendUrl, infoRepeater, image, email }) {
  
  const [showLoader, setShowloader] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false)
  const { register, handleSubmit, formState: {errors} } = useForm()

  /**
   * Sends contact form data to 'form' endpoint
   * 
   * @param {object} data 
   *
   * @returns {promise}
   */
  const sendFormData = (data) => fetch(`${backendUrl}/wp-json/breyta/v1/form`,{
      method: "POST", 
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(data) 
    }
  )

  /**
   * Handles form submit and local state based on server response
   * 
   * @param {Event object} e 
   * @param {object} data
   *
   */
  const onSubmit = async (data, e) => {
    e.preventDefault();
    
    const completeData = {
      ...data,
      mail_to: email,
      site_url: window.location.origin
    }
    setShowloader(true);
    setErrorDialog(false)

    fetchWithTimeout(
      10000,
      sendFormData,
      completeData
    ).then((response) => {
      setShowloader(false)

      if(!response.ok) {
        throw new Error(`${response.statusText}`)
      }
      return response.json()
    
    }).then((responseData) => {
      setShowloader(false);
      if(responseData.status === 200) {
        e.target.reset() // reset form fields after successfully submitting
        navigate('/form-submitted');
      } else {
        setErrorDialog(true)
      }
    }).catch(e => {
      console.error(e)
      setShowloader(false);
      setErrorDialog(true)
    })
  } 

  return (
    <>
      <div className="contact__form">
        <div className="contact__form-wrapper">
          <form onSubmit={handleSubmit(onSubmit)} className="contact__form-content" noValidate>
            <InputField
              type="text"
              name="name"
              label="Name"
              register={register}
              error={errors.name && <p className="contact__form-error">{errors.name.message}</p>}
            />
            <InputField
              type="email"
              name="email"
              label="Email"
              register={register}
              error={errors.email && <p className="contact__form-error">{errors.email.message}</p>}
            />
            <InputField
              type="text"
              name="companyName"
              label="Company Name"
              isRequierdField={false}
              register={register}
            />
            <InputField
              type="textarea"
              name="message"
              label="Message"
              register={register}
              error={errors.message && <p className="contact__form-error">{errors.message.message}</p>}
            />
            <ContactFormConsent 
              type="checkbox"
              name="check"
              labelFor="check"
              register={register}
              error={errors.check?.type === 'required'}
            />
            <div className="contact__form-submit">
              <CtaShuffle type="button" value="Send" showLoader={showLoader} />
            </div>
          </form>
        </div>
      </div>
      <ContactInfo infoRepeater={infoRepeater} image={image} />
      {errorDialog && <ErrorDialogBox onClose={() => setErrorDialog(false)} /> } 
    </>
  )
}

ContactForm.propTypes = {
  backendUrl: PropTypes.string,
  infoRepeater: PropTypes.array,
  image: PropTypes.object,
  email: PropTypes.string.isRequired
}